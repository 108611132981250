html {
    -webkit-font-smoothing: auto;
}

body {
    background: #fff;
    color: #1d243c;
    line-height: 1.62857;
    font-family: "Avenier","Open Sans",sans-serif;
    font-size: 15px;
}

h1 {
    color: #7f9ed2;
    font-weight: 400;
    line-height: 1.1;
}

#wrap {
    background-color: #013d79;
}

header {
    height: 147px;
}

header > a {
    display: block;
    padding: 12px 0 0 8px;
}

.navbar-brand {
    padding: 12px 0 0 8px;
}

.navbar-primary {
    border-radius: 0 0 40px 40px;
    background-clip: padding-box;
    margin-top: 1px;
}

.navbar-primary > li > a {
    background-color: #fdb13f;
    background-image: -webkit-linear-gradient(top, #fdb13f, #f99d29);
    background-image: linear-gradient(to bottom, #fdb13f, #f99d29);
    border-right: 1px solid #f18810;
    border-left: 1px solid #ffbf38;
    color: #fff;
    font-size: 20.4px;
    padding: 8px 29px 14px 29px;
}

.navbar-primary > li.active > a,
.navbar-primary > li > a:hover {
    background-color: #ffc641;
    background-image: -webkit-linear-gradient(top, #ffc641, #ffb929);
    background-image: linear-gradient(to bottom, #ffc641, #ffb929);
}

.navbar-primary > li:first-child > a {
    border-left: 0;
    border-radius: 0 0 0 20px;
    padding-left: 40px;
    padding-right: 27px;
}

.navbar-primary > li:last-child > a {
    border-right: 0;
    border-radius: 0 0 20px 0;
    padding-right: 30px;
}

.help-numbers {
    background: #fff;
    border-radius: 10px 0 0 10px;
    float: right;
    font-size: 12px;
    margin: 55px 14px 0 0;
    padding: 8px 39px 8px 12px;
    text-align: right;
    width: 190px;
}

.help-numbers li {
    border-top: 1px solid #d8dade;
    padding: 2px 0;
}

.help-numbers li:first-child {
    border-top: 0;
}

.help-numbers strong {
    font-size: 14px;
}

.help-numbers a {
    color: #5276CF;
}

.help-now-box {
    margin: 17px 0 0 -73px;
}

.help-now-circle {
    background: #fff;
    border-radius: 100%;
    height: 150px;
    margin: 4px 0 0 3px;
    position: absolute;
    width: 150px;
    z-index: -1;
}

.help-now {
    background: url(https://www.lifeline.org.au/images/UserUploadedImages/543/help-now-button.png) no-repeat;
    background-size: 160px;
    color: transparent;
    display: block;
    font: 0/0 a;
    height: 160px;
    position: absolute;
    width: 160px;
}

.help-now:hover {
    background-position: 0 -160px;
}

.btn-search {
    background: transparent url(https://www.lifeline.org.au/images/UserUploadedImages/11/SearchButton.png) no-repeat;
    bottom: -25px;
    color: transparent;
    font: 0/0 a;
    height: 38px;
    position: absolute;
    right: 23px;
    width: 38px;
}

.btn {
    -moz-user-select: none;
    background-image: none;
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.62857;
    padding: 6px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.btn-search:hover {
    background-position: 0 -37px;
}

#search-form {
    background: #aab8df;
    border-radius: 0 0 10px 10px;
    padding: 14px;
    position: absolute;
    right: 0;
    z-index: 1;

	/* These measurements make the form appear on top of the "About Lifeline"
	   button */
    bottom: -99px;
    width: 193px;
}

#search-form input {
    width: 100%;
}

.breadcrumb {
    color: #7f7f7f;
    clear: both;
    padding: 15px 10px 0;
    font-size: 13px;
}

.breadcrumb li + li:before {
    content: "/\00a0"; /* Unicode space added since inline-block means non-collapsing white-space */
    padding: 0 5px;
}

.breadcrumb a {
    color: #888;
    text-decoration: underline;
}

footer {
    background: #27324c;
    color: #fff;
    font-size: 12px;
    padding: 15px 0 10px;
}

footer a {
    color: #ccc;
}

footer a:hover {
    color: #fff;
}

.footer-logos a {
    margin-left: 30px;
}

.footer-logos a:first-child {
    margin-left: 0;
}

.footer-links {
    margin-bottom: 10px;
    margin-top: 10px;
}

.footer-external {
    margin-top: 64px;
}

.footer-line {
    font-size: 10px;
}

.backToResultsListLink .fa,
.backToServiceTypesListLink .fa {
    color: #003575 !important;
}

.directory-details-top {
	background-color: #fff;
}

.directory-details-controls a {
    text-shadow: 0 1px 1px rgba(51, 51, 51, 0.22);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

#issAdvancedSearchForm,
.advanced-search-title,
.directory-details-body .tab-content .row {
    background: #fff;
}

.directory-details-header {
    font-size: 30px;
    color: #7f9ed2;
    font-weight: 400;
    line-height: 1.1;
    margin-top:0px;
}

.directory-details-label {
    margin-top: 10px;
}


.directory-details-info-row dd,
.directory-details-info-row dt {
    line-height: 1.62857;
}

.directory-details-body-tabs li,
.directory-details-body-tabs li:first-child,
.directory-details-body-tabs li:last-child {
    border: 0 !important;
}

.is-desktop .directory-details-info-row {
    font-size: 15px;
}

a,
a:hover {
    color: #003575;
}

.search-spinner {
    position: absolute;
    padding-left: 10px;
    padding-right: 0;
    padding-top: 13px;
}

#totalRecords_container {
    background-color: #fff;
    color: #000;
    font-weight: normal;
}

.services-search-row {
    height: 108px;
}

.services-search-row .services-search-row-main {
    margin: 0;
    width: 670px;
    height: 100px;
}

.services-search-row .services-search-row-main form {
    margin-top: 0;
}

.services-search-row .services-search-row-main form span.twitter-typeahead,
.services-search-row .services-search-row-main .search-services-box,
.services-search-row .services-search-row-main .search-locations-box {
    border: 0;
    height: 45px;
}

.services-search-row .services-search-row-main .search-services-box {
    border-right: #013d79 1px solid;
}

.services-search-row .services-search-row-main .help-block {
    color: #fff;
    margin-bottom: 0px;
}

.services-search-row .services-search-row-auxiliary .btn-auxiliary {
    background: none;
    color: #fff;
    height: 45px;
    width: auto;
    padding: 10px 15px;
    text-shadow: 0 1px 1px rgba(51, 51, 51, 0.22);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

.services-search-row .services-search-row-auxiliary .btn-auxiliary:first-child {
    margin-left: 10px;
}

.services-search-row .services-search-row-auxiliary .btn-auxiliary:hover,
.services-search-row .services-search-row-auxiliary .btn-auxiliary:focus {
    background-color: #062240;
    text-decoration: none;
}

.services-search-row,
.top-panel-mobile {
    background: none;
}

.back-menu-mobile,
.back-menu-mobile .fa-arrow-left {
    color: white;
}

.back-menu-mobile:hover {
    color: white;
    text-decoration: underline !important;
}

.services-search-row .services-search-row-auxiliary {
    border-left: none;
    width: 310px;
    padding-top: 18px;
    margin-left: 20px;
}


#leaflet-map .map-info-overlay {
    background-color: #f9f8f7;
    width: 500px;
}

#leaflet-map .map-info-overlay p.map-message {
    padding: 25px 0 0;
    font-size: 17px;
    color: #333333;
    width: 100%;
}

#leaflet-map .map-info-overlay .info-container {
    color: #333333;
}

#leaflet-map .map-down-arrow {
    display: none;
}

.leaflet-popup-content h3,
.leaflet-popup-content strong {
    font-weight: bold;
}

.btn-primary {
    background-color: #f5a417;
    border-radius: 0;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    height: 45px;
    padding-left: 45px;
    padding: 12px 18px;
    position: relative;
    text-shadow: 0 1px 1px rgba(51, 51, 51, 0.22);
    text-transform: uppercase;
}

.btn-default {
    background-color: #4d4d4d;
    border-radius: 0;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    height: 45px;
    padding-left: 45px;
    padding: 12px 18px;
    position: relative;
    text-shadow: 0 1px 1px rgba(51, 51, 51, 0.22);
    text-transform: uppercase;
}

#iss-search_submit {
    height: 45px;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #d08709;
    color: #fff;
    border-color: transparent;
}

.panel-heading,
.directory-details-body-tabs a {
    background-color: #013d79 !important;
}

.panel-title > a {
    color: #fff;
}

.directory-details-body-tabs .active > a {
    background: #013060 none repeat scroll 0 0 !important;
    color: #e6e6e6 !important;
}

.directory-details-body-tabs a {
    text-shadow: 0 1px 1px rgba(51, 51, 51, 0.22);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

.directory-details-body-tabs a:hover {
    background-color: #062240 !important;
}

.services-search-suggestions {
	display: none;
	position: absolute;
	background: white;
	left: 118px;
	top: 73px;
	width: 519px;
	z-index: 10;
}

.services-search-suggestions .list-group {
    margin-bottom: 0;
}

.services-search-suggestions a.list-group-item:hover,
.services-search-suggestions a.list-group-item:focus,
.services-search-suggestions a.list-group-item.active,
.services-search-suggestions a.list-group-item.active:hover,
.services-search-suggestions a.list-group-item.active:focus {
    background-color: #638000;
    border-color: #638000;
    color: #fff;
}

.service-directory-modal .modal-header {
    padding: 20px 20px 0;
}

.service-directory-modal .modal-header > h1 {
    padding:0;
}

.services-search-row .services-search-row-main form button,
.services-search-row .services-search-row-main form input,
.services-search-row .services-search-row-main form span.twitter-typeahead {
    height: 45px;
}

@media (max-width: 767px) {
    .navbar-primary,
    footer {
        display: none;
    }

    .services-search-row {
        min-width: auto;
    }

    .services-search-row-auxiliary {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 926px) {
    .services-search-row .services-search-row-auxiliary {
        width: auto;
    }

    .services-search-row .services-search-row-auxiliary .text {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    .container {
        width: auto;
    }

    .services-search-row {
        min-width: auto;
    }

    #iss-search_form {
        width: 100%;
        margin-left: 0px;
    }

    .services-search-row .services-search-row-main {
        width: 380px;
    }

    .services-search-row .services-search-row-main form span.twitter-typeahead,
    .services-search-row .services-search-row-main .search-services-box,
    .services-search-row .services-search-row-main .search-locations-box {
        width: 220px;
    }

    .services-search-row .services-search-row-main form button {
        width: 150px;
    }

    .services-search-row .services-search-row-auxiliary {

    }

    .search-services-box,
    .search-locations-box {
        width: 75%;
    }

}

@media only screen and (min-width: 927px) and (max-width: 1023px) {
    /* unset value from iss.js */
    .services-search-row-auxiliary .btn-auxiliary .text {
        display: initial;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {

    .services-search-row {
        min-width: auto;
    }

    #iss-search_form {
        width: auto;
    }

    .services-search-row .services-search-row-main {
        width: 610px;
    }

    .services-search-row .services-search-row-main form button {
        width: 170px;
    }

}

.label-round.label-success {
    border-color: #27324C;
    color: #27324C;
}

#as-mobile {
    background-color: #fff;
}

.search-box-mobile {
    background: none;
    background-color: #013d79;
    padding: 15px 10px;
}

.search-box-mobile .search-services-box,
.search-box-mobile .search-locations-box {
    border: 0px;
    height: 45px;
}

.search-box-mobile .search-services-box {
    border-right: none;
    border-bottom: #013d79 1px solid;
}

#advancedSearchMobileContainer .form-buttons > button {
    display:block;
    float:none;
    width:100%;
    margin-bottom: 10px;
}

#advancedSearchMobileContainer .form-buttons .cancel-advanced-search {
    border-color: transparent;
}

/* Hide the google conversion iframe */
iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}
